import React from "react";

import { 
    Layout,
    SimplePage
} from "../components";

const FAQPage = () => {

    const blocks = [
        {
            content: 'Only 1 single bet is available per challenge. But don’t worry… We upload challenges regularly so you have a good chance to win something cool if you check Playdrop on a daily basis.',
            title: 'How many times can I bet?',
        },
        {
            content: 'These challenges are public demos from the CS:GO community',
            title: 'Where are these challenges from?',
        },
        {
            content: [
                'Each bet is open for no more than 30 minutes every round hour, so if you miss it you will have to wait until it’s open the hour after that and so on.',
            ],
            title: 'Why is the bet locked?',
        },
        {
            content: [
                'There are 2 main factors we use to decide our winners:',
                'The first factor, of course, is the bet itself where our system calculates who had the right answer.',
                'The second factor is the time it took for a player to place the bet. The first one to place a correct(or the most correct, depending on the challenge) bet will be the winner.',
                'Make sure to check out our upcoming challenges and be the first one to place a bet when they go live ;)',
            ],
            title: 'How are the Winners decided?',
        },
        {
            content: [
                ' In a simple trade offer through the steam platform. We do it manually and it should take no longer than 72 hours.',
            ],
            title: 'How are the prizes delivered?',
        },
        {
            content: [
                'NO. Playdrop isn’t a betting site. It’s completely free to play and you don’t have to deposit skins or money. All you have to do is visit Playdrop on a regular basis and guess the play 🙂',
            ],
            title: 'Do I have to make a deposit to play on Playdrop?',
        },
        {
            content: [
                'Yes! The login is done through Discord itself and we do not collect or store any data about your Discord account(nor we need to). The only thing we use is your Discord public username for it to be displayed on the live chat.'
            ],
            title: 'Is Playdrop secured?',
        },
        {
            content: [
                'ABSOLUTELY NOT. Playdrop.gg is a completely free website where you can earn free skins by “betting” on games. There are no deposits of any kind and nor will be in the future. So there’s no reason to ask you for your API key permission, especially when the login is done with Discord.'
            ],
            title: 'Do you ask for API Keys?',
        },
        {
            action: {
                label: 'contact us on our Discord channel',
                onClick: () => window.open('https://discord.com/invite/cPJYPhfPwQ', '_blank'),
                type: 'inline',
            },
            content: [
                'Yes. If you have any technical issues or need help with a question not available here, feel free to',
            ],
            title: 'Do you have support?',
        },
        {
            content: [
                'The winners are notified on our Discord channel or by email if you shared it with us. And you can always just visit Playdrop.gg once the challenge is over to see if you won ;)',
            ],
            title: 'How do I get notified if I win?',
        },
        {
            action: {
                label: 'Click here to join',
                onClick: () => {
                    window.open('https://discord.com/invite/cPJYPhfPwQ', '_blank');
                },
                type: 'inline',
            },
            content: [
                'Yes we have!',
            ],
            title: 'Do you have a Discord channel?',
        },
        {
            action: {
                label: 'Click here to join',
                onClick: () => {
                    window.open('https://www.youtube.com/@playdropgg', '_blank');
                },
                type: 'inline',
            },
            content: [
                'Yes we have!',
            ],
            title: 'Do you have a Youtube channel?',
        },
        {
            action: {
                label: 'Click here to go to our Twitter page',
                onClick: () => {
                    window.open('https://twitter.com/Playdropgg', '_blank');
                },
                type: 'inline',
            },
            content: [
                'Yes we have!',
            ],
            title: 'Do you have a Twitter page?',
        },
        {
            content: [
                'Once the challenge ends, the result (which is the full gameplay video) is released and made public on our official Youtube channel and on Playdrop.gg.',
            ],
            title: 'When & where can we see a specific challenge result?',
        },
        {            
            action: {
                label: 'here',
                onClick: () => window.open('https://discord.com/invite/cPJYPhfPwQ', '_blank'),
                type: 'inline',
            },
            content: [
                'We pay close attention to our challenges but sometimes it can be that a mistake was made. We’re only humans after all… So if you spot a mistake in a challenge, then please let us know by submitting a mistake report',
            ],
            title: 'I think you made a mistake with a certain challenge, where can I report about this?',
        },
        {
            content: [
                'It will be a bit boring to have a single winner every challenge… So we made sure there will be multiple winners per challenge by adding extra winning spots. The spot numbers are decided based on the prize money and our own generosity ;)',
            ],
            title: 'What are these available prize spots?',
        },
        {
            content: [
                'No. You can place a single bet on a single challenge. Once the bet is placed, you cannot change it.',
            ],
            title: 'Can I edit my existing bet or place a new one for the same challenge?',
        },
    ];
    const subtitle = "Everything you want to know about PlayDrop.";
    const title = "FAQ";

    return (
        <Layout 
            title="FAQ"
        >
            <SimplePage
                blocks={blocks}
                subtitle={subtitle}
                title={title}
            />
        </Layout>
    )
}
export default FAQPage;